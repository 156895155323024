
























import { defineComponent } from "@vue/composition-api";
import AuthenticationBasePage from "@/components/AuthenticationBasePage.vue";
import ResetPasswordForm from "@/components/authentication/ResetPasswordForm.vue";

export default defineComponent({
  name: "ResetPassword",
  components: { AuthenticationBasePage, ResetPasswordForm },
});
